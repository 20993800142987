/* 
  PuzzleLayout.css
  Modern, clean aesthetic
*/

/* -------------------------------------------------------------
   1) Root Variables for Colors & Font
   You can load "Open Sans" in your HTML head, for example:
   <link rel="preconnect" href="https://fonts.googleapis.com">
   <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet">
-------------------------------------------------------------- */

:root {
  --primary-color: #2f8cbb;
  --secondary-color: #f5f9fc;
  --sidebar-bg: #ffffff;
  --sidebar-border: #e2e2e2;
  --sidebar-shadow: rgba(0, 0, 0, 0.05);
  --text-color: #333;
  --heading-color: #2a2a2a;
  --background-color: #fdfdfd;
  --font-family: 'Open Sans', sans-serif;
}

/* -------------------------------------------------------------
   2) Global Resets / Body
-------------------------------------------------------------- */
html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--text-color);
  background: var(--background-color);
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* -------------------------------------------------------------
   3) The main container (sidebars + center puzzle)
      arranged as a row on larger screens
-------------------------------------------------------------- */
.puzzle-layout {
  display: flex; /* row layout by default on desktop */
  min-height: 100vh;
}

/* -------------------------------------------------------------
   4) Mobile Header
      hidden on desktop, visible only on mobile
-------------------------------------------------------------- */
/* Ensure the mobile header is positioned relative so that the dropdown can be positioned absolutely */
.mobile-header {
  position: relative;
  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* PuzzleLayout.css additions for metadata dropdown in mobile header */
/* Position the metadata dropdown in the top right */
.metadata-dropdown {
  position: absolute;
  top: 0.5rem;   /* Adjust as needed */
  right: 0.5rem; /* Adjust as needed */
}

/* Style the dropdown toggle button */
.dropdown-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.3rem;
}

/* Dropdown icon styling with rotation when open */
.dropdown-icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.metadata-content p {
  margin: 0.5rem 0;
  line-height: 1.4;
}

.metadata-content p2 {
  color: var(--primary-color);
}

/* Metadata content styling */
.metadata-content {
  margin-top: 0.5rem;
  background-color: #fff;
  color: #333;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: 2.5rem; /* position below the toggle */
  z-index: 10;
}

/* -------------------------------------------------------------
   5) Sidebars (desktop only)
-------------------------------------------------------------- */
.sidebar {
  flex: 0 0 25%;
  background-color: var(--sidebar-bg);
  border-right: 1px solid var(--sidebar-border);
  padding: 1.5rem;
  box-shadow: 2px 0 6px var(--sidebar-shadow);
  /* Slight shadow for depth */
}

/* Adjust the left sidebar to prevent overlap with back button */
.left-sidebar {
  border-right: 1px solid var(--sidebar-border);
  border-left: none; /* remove left border if any */
  padding-top: 70px; /* Add padding to the top to make room for the back button */
}

.right-sidebar {
  border-left: 1px solid var(--sidebar-border);
  border-right: none; 
  box-shadow: -2px 0 6px var(--sidebar-shadow);
}

/* Sidebar headings, text styling */
.sidebar h2 {
  margin-top: 0;
  color: var(--heading-color);
  font-weight: 600;
}
.sidebar p {
  line-height: 1.5;
  margin-bottom: 1rem;
}

.brand-sidebar {
  margin-bottom: 1rem;
}

.brand-sidebar h1 {
  font-size: 2rem;
  color: var(--primary-color);
  margin: 0;
}

/* -------------------------------------------------------------
   6) Center puzzle area
-------------------------------------------------------------- */
.puzzle-center {
  flex: 1.0; 
  padding: 2rem;
  text-align: center;
  background-color: var(--secondary-color);
}

/* Example styling for heading in puzzle center */
.puzzle-center h1 {
  margin-top: 0;
  color: var(--heading-color);
  font-weight: 600;
  margin-bottom: 1.5rem;
}

/* -------------------------------------------------------------
   7) Buttons, etc.
   You might already have styles in PuzzleButtons.css, but here's
   a quick example for modern button aesthetics.
-------------------------------------------------------------- */

button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  /* padding: 0.7rem 1.2rem; */
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease;
  margin: 0.2rem; /* slight spacing between buttons */
}

button:hover {
  background-color: #3ba8e2; /* darker shade */
}

.button-icon {
  margin-right: 0.4rem;
}

.error-message {
  color: #333;
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  margin: 2rem;
  margin-top: 8rem; /* Add top margin to shift text down */
  max-width: 600px; /* Limit width of text box */
  margin-left: auto; /* Center the text box */
  margin-right: auto;
}

/* -------------------------------------------------------------
   8) Responsive Media Queries
   For screens <= 768px wide, stack elements vertically,
   hide sidebars, show mobile header
-------------------------------------------------------------- */
/* ----- Mobile (<= 768px) ----- */
@media (max-width: 768px) {
  .puzzle-layout {
    display: block;
  }

  .mobile-header {
    display: block;
    background-color: var(--primary-color);
    color: #fff;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .sidebar {
    display: none; /* hide sidebars on mobile */
  }

  .puzzle-center {
    margin: 0 auto;
    max-width: 95%;
    background-color: var(--secondary-color);
  }
}

/* ----- Desktop (>= 769px) ----- */
@media (min-width: 769px) {
  .mobile-header {
    display: none !important;
  }

  /* If you want sidebars visible on desktop: */
  .sidebar {
    display: block;
  }

  /* Possibly set puzzle layout as flex for desktop: */
  .puzzle-layout {
    display: flex;
    /* or whatever layout you want for big screens */
  }
}

/* Back button styling */
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: #246d94;
}

/* Make sure the back button is visible on mobile too */
@media (max-width: 768px) {
  .back-button {
    top: 10px;
    left: 10px;
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
