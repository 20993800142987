/* PaymentModal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    width: 400px;
    max-width: 90%;
    max-height: 80vh; /* Limit height to 80% of viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    text-align: center;
  }
  
  /* Add some spacing for form elements */
  .modal-content form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  /* Style the buttons consistently */
  .modal-content button {
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 16px;
  }
  
  .modal-content button[type="submit"] {
    background-color: #2f8cbb;
    color: white;
    border: none;
  }
  
  .modal-content button[type="submit"]:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .modal-content button:not([type="submit"]) {
    background-color: transparent;
    border: 1px solid #cccccc;
    color: #333;
  }
  