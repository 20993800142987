/* LoadingAnimation.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }
  
  .loading-icon {
    font-size: 48px;
    color: var(--primary-color);
    margin-bottom: 1rem;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { 
      transform: rotate(0deg);
    }
    100% { 
      transform: rotate(360deg);
    }
  }
  