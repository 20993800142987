/* PuzzleButtons.css */

/* Generic styles for all puzzle buttons */
button.restart-button,
button.next-button,
button.hint-button {
  display: inline-flex;
  align-items: center;
  gap: 6px; /* small space between icon and text */
  padding: 8px 12px;
  margin: 8px;
  border: none;
  border-radius: 4px;
  background-color: #43b0f3;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

button.restart-button:hover,
button.next-button:hover,
button.hint-button:hover {
  /* background-color: #666; */
}

/* Icon class if you want to specifically style the icon */
.button-icon {
  /* e.g., increase icon size or color if desired */
  font-size: 60px;
}
