/* Overall page container */
.landing-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #ffffff, #f0f0f0);
    font-family: 'Helvetica Neue', Arial, sans-serif;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .landing-container h1 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 2rem;
    color: #333;
  }

  .brand-header {
    text-align: center;
    margin-bottom: 1rem;
  }

  .brand-header h2 {
    font-size: 2.5rem;
    color: #2f8cbb; /* Assuming you have a CSS variable for your brand color */
    margin: 0;
  }
  
  /* Username input styling */
  .input-container {
    margin-bottom: 2rem;
    width: 17%;
  }
  
  .input-container input {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 104%;
    font-size: 1rem;
    outline: none;
  }

  /* New styles for the date-selectors */
  .date-selectors {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }

  .date-selectors label {
    font-size: 1rem;
    color: var(--text-color, #333);
    font-weight: 500;
  }

  .date-selectors select {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: var(--text-color, #333);
  }

  .selectors-wrapper {
    position: relative;
  }
  
  .upgrade-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.9em;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    white-space: nowrap;
    z-index: 1000;
  }
  
  .upgrade-tooltip::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  
  .date-selectors:hover .upgrade-tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  /* Style for disabled selects */
  select:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  /* The slider container */
  .slider-button {
    position: relative; /* needed for absolutely-positioned icons */
    width: 300px;
    margin: 1rem 0;
  }
  
  /* icons on the track */
  .icon {
    padding-bottom: 1%;
    position: absolute;
    top: 50%; 
    font-size: 24px;
    color: #444;
    transform: translateY(-50%);
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .pawn-icon {
    left: 12px; 
    /* the pawn starts left, fades out */
  }
  
  .queen-icon {
    right: 12px;
    /* the queen starts right, fades in */
  }
  
  /* Range slider track */
  .slider-button input[type="range"] {
    width: 101%;
    -webkit-appearance: none;
    background: #ddd;
    height: 40px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin: 0;
    transition: background 0.3s;
  }
  
  /* Chrome / Safari Thumb */
  .slider-button input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 40%;
    height: 40px;
    border-radius: 5px;
    background: #2f8cbb;
    /* arrow icon in the center */
    background-image: url('./right-arrow-svgrepo-com.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    box-shadow: 0 0 2px rgba(0,0,0,0.3);
    cursor: pointer;
    transition: background 0.2s, transform 0.3s;
  }
  
  /* Firefox Thumb */
  .slider-button input[type="range"]::-moz-range-thumb {
    width: 70px;
    height: 40px;
    border-radius: 5px;
    background: #2f8cbb url('./right-arrow-svgrepo-com.svg') no-repeat center;
    background-size: 18px 18px;
    cursor: pointer;
  }
  
  .slider-instructions {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }
  
  /* Add these new styles */
  .auth-section {
    position: absolute;
    top: 20px;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .auth-section button {
    background-color: #2f8cbb;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }

  .auth-section button:hover {
    background-color: #246d94;
  }

  .auth-section p {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
  }
  